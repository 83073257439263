"use strict";

var app = {
  settings: {},
  vars: {
    breakpoints: {
      laptop: 1024
    }
  },
  init: function init() {
    this.initGlobalExtensions();
    this.initLocalExtensions($('body'));
  },
  parse: function parse($context) {
    this.initLocalExtensions($context);
  },
  initGlobalExtensions: function initGlobalExtensions() {
    var t = this;

    for (var globalExt in t.globalExt) {
      if (!app.obj[globalExt]) {
        app.obj[globalExt] = new t.globalExt[globalExt]();
      }
    }
  },
  initLocalExtensions: function initLocalExtensions($context) {
    var t = this;

    for (var ext in t.ext) {
      var $els = $context.findOrSelf('.js-' + ext);
      $els.each(function () {
        var fn = new t.ext[ext]($(this));
        $(this).data('obj_' + ext, fn);
        if (app.obj[ext] === undefined) app.obj[ext] = [];

        if ($(this).data('objectName')) {
          app.obj[ext][$(this).data('objectName')] = fn;
        } else {
          app.obj[ext].push(fn);
        }
      });
    }
  },
  ext: {},
  globalExt: {},
  obj: {}
};
$(function () {
  app.init();
});

$.fn.findOrSelf = function (selector) {
  return this.find(selector).add(this.filter(selector));
};

app.globalExt['cookies-marketing-overlay'] = function () {
  var api = {};
  app.vars.marketingCookiesTrigger = false;

  var init = function init() {};

  app.vars.openMarektingCookiesOverlay = function ($trigger) {
    app.vars.marketingCookiesTrigger = $trigger;
    var overlayId = 'cookie-makering-settings';
    var $overlay = $('.js-overlay').filter("[data-overlay-id=\"".concat(overlayId, "\"]"));

    if ($overlay) {
      $overlay.addClass('slide-in');
      app.vars.fixSite();
    }
  };

  init();
  return api;
};

app.globalExt['ellipsis'] = function () {
  var api = {};

  var init = function init() {
    Ellipsis({
      'lines': 2
    });
    var ellipsisConf = {
      className: false
    };
    app.vars.ellipsis = Ellipsis(ellipsisConf);
  };

  init();
  return api;
};

app.globalExt['fb-track'] = function () {
  var api = {};

  var init = function init() {
    $('body').on('click', '.js-fb-track', fb_track);

    if ($('body').data('fb-advanced-tracking')) {
      setTimeout(function () {
        if (loops.store.cookies_allowed["marketing"] && window['fbq']) {
          fbq('track', 'Visit30s'); // console.log('fb-track - Visit30s');
        }
      }, 30000);
    }

    if ($('body').data('country') == 'UK' && loops.store.cookies_allowed["marketing"] && $('body').data('fb-advanced-tracking') && window['fbq']) {
      if ($('body').data('page-id') == '1674') {
        fbq('track', 'BuyOnline'); // console.log('fb-track - BuyOnline');
      } else if ($('body').data('page-id') == '1676') {
        fbq('track', 'Pharmacy'); // console.log('fb-track - Pharmacy');
      }
    }
  };

  var fb_track = function fb_track(e) {
    if (loops.store.cookies_allowed["marketing"] && $('body').data('fb-advanced-tracking') && window['fbq']) {
      fbq('track', $(this).data('fb-track-label')); // console.log('fb-track - '+$(this).data('fb-track-label'));

      e.preventDefault();
    }
  };

  init();
  return api;
};

app.globalExt['fix-site'] = function () {
  var api = {};
  var $body = $('body');
  var $site = $('.js-content');
  var scroll_position = 0;

  var init = function init() {};

  app.vars.fixSite = function () {
    scroll_position = $(window).scrollTop();
    $body.addClass('fixed');
    $site.addClass('fixed');
    $site.css({
      marginTop: -scroll_position + 'px'
    });
  };

  app.vars.unfixSite = function () {
    $body.removeClass('fixed');
    $site.removeClass('fixed');
    $site.removeAttr('style');
    $(window).scrollTop(scroll_position);
  };

  init();
  return api;
};

app.globalExt['ga-event'] = function () {
  var api = {};

  var init = function init() {
    $('body').on('click', '.js-ga-event', send_event);
    $('body').on('click', '.js-faq-question.is-active', send_event);
  };

  var send_event = function send_event(e) {
    if (loops.store.cookies_allowed && loops.store.cookies_allowed["tracking"] && $('body').data('gtm-advanced-tracking')) {
      var ga_label = $(this).data('ga-label');
      dataLayer.push({
        'ga_category': $('body').data('page-title'),
        'ga_action': $(this).data('ga-action'),
        'ga_label': ga_label,
        'event': 'ga_event'
      }); // console.log({
      //     'ga_category': $(this).data('ga-category-overwrite') ? $(this).data('ga-category-overwrite') : $('body').data('page-title'),
      //     'ga_action': $(this).data('ga-action'),
      //     'ga_label':  ga_label,
      //     'event': 'ga_event',
      // });
      // e.preventDefault();
    }
  };

  init();
  return api;
};

app.globalExt['global-structure'] = function () {
  var api = {};

  var init = function init() {};

  init();
  return api;
};

app.globalExt['object-fit-images'] = function () {
  var api = {};

  var init = function init() {
    objectFitImages();
    objectFitPolyfill();
  };

  init();
  return api;
};

app.globalExt['preload'] = function () {
  var api = {};

  var init = function init() {
    $("body").removeClass("preload");
  };

  init();
  return api;
};

app.globalExt['global-structure'] = function () {
  var api = {};

  var init = function init() {
    app.getQueryStringParamter = function (name) {
      var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };

    app.getUrWithoutQueryString = function () {
      return window.location.href.replace(window.location.search, '');
    };
  };

  init();
  return api;
};

app.globalExt['captcha'] = function () {
  var api = {};

  var init = function init() {
    if ($('.js-recaptcha').length) {
      if (app.recaptchaTriggered) {
        return;
      }

      app.recaptchaTriggered = true;

      window.reCaptchaCallback = function () {
        app.recaptchaReady = true;
        $(window).trigger('recaptchaReady');
      };

      $.getScript("https://www.google.com/recaptcha/api.js?onload=reCaptchaCallback&render=explicit&hl=" + $('body').data('lang'));
    }
  };

  init();
  return api;
};

app.globalExt['youtube-api'] = function () {
  var api = {};
  var check_marketing_cookies = $('body').data('check-marketing-cookies');

  var init = function init() {
    if (!check_marketing_cookies) {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  };

  init();
  return api;
};

app.ext['overlay-link'] = function ($el) {
  var api = {};
  var overlayId = $el.data('overlay-id');
  var $overlay = $('.js-overlay').filter("[data-overlay-id=\"".concat(overlayId, "\"]"));
  var key_slug = $el.data('overlay-key-slug');

  var init = function init() {
    addEventHandlers();
  };

  var addEventHandlers = function addEventHandlers() {
    $el.on('click', openOverlay);
  };

  var openOverlay = function openOverlay(e) {
    e.preventDefault();

    if ($overlay) {
      $overlay.addClass('slide-in');
      app.vars.fixSite();

      if (key_slug) {
        history.pushState(null, null, app.getUrWithoutQueryString() + '?' + key_slug + '=' + $el.data('overlay-slug'));
      }
    }
  };

  init();
  return api;
};

app.ext['overlay'] = function ($el) {
  var api = {};
  var $close = $el.find('.js-overlay-close');
  var key_slug = $el.data('overlay-key-slug');

  var init = function init() {
    addEventHandlers();
    showOverlay();
  };

  var addEventHandlers = function addEventHandlers() {
    $close.on('click', closeOverlayClick);
    $el.on('click', '.js-overlay-set-marketing', setMarketingCookikes);
  };

  var showOverlay = function showOverlay() {
    var overlay_slug = app.getQueryStringParamter(key_slug);
    var slug = $el.data('overlay-slug');

    if (overlay_slug && slug == overlay_slug || slug == 'initial') {
      $el.addClass('slide-in');
      app.vars.fixSite();
    }
  };

  var closeOverlayClick = function closeOverlayClick(e) {
    e.preventDefault();
    e.stopPropagation();
    closeOverlay();
  };

  var closeOverlay = function closeOverlay(e) {
    $el.removeClass('slide-in');
    app.vars.unfixSite();
    history.pushState(null, null, app.getUrWithoutQueryString());

    if ($el.data('overlay-slug') == 'initial') {
      Cookies.set('initial_overlay', 1);
    }
  };

  var setMarketingCookikes = function setMarketingCookikes() {
    app.vars.marketingCookiesTrigger.trigger('click');
    closeOverlay();
  };

  init();
  return api;
};